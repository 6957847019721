import { useNavigate } from "react-router-dom";
import { NavLink as NavLinkRouter } from "react-router-dom";

export const NavLink = ({ linkTitle, classNames, linkEnum, isNewPath }) => {
    const currentLink = window.location.pathname;
    const navigateTo = useNavigate();
    const handleClick = () => {
        if (isNewPath) {
            window.location.href = linkEnum;
            return;
        }
        navigateTo(linkEnum);
    };
    return (
        <button
            className={`${classNames} transition duration-300 text-lg px-2 cursor-pointer hover:underline ${
                currentLink === linkEnum ? "text-blue-50" : "text-gray-400"
            }`}
            onClick={handleClick}
        >
            {linkEnum ? (
                <NavLinkRouter href={`${linkEnum}`}>{linkTitle}</NavLinkRouter>
            ) : (
                linkTitle
            )}
        </button>
    );
};
