export const MainLayout = ({ header, body, footer }) => {
    return (
        <div className="h-full min-h-screen bg-blue-50">
            {header && <div className="max-h-fit min-w-full">{header}</div>}
            {body && (
                <div className="bg-slate-700 min-h-screen text-gray-50 pb-4 px-8">
                    {body}
                </div>
            )}
            {footer && <div className="h-fit min-w-full">{footer}</div>}
        </div>
    );
};
