import { NavLink } from "../../navlink";
import { Title } from "../../title";

export const Cerberus = () => {
    return (
        <div>
            <Title
                title={"Cerberus"}
                subtitle={"An upcoming action platformer"}
            />
            <div className="">
                <NavLink linkTitle={"Steam page"} />
                <NavLink linkTitle={"Screenshots"} />
            </div>
        </div>
    );
};
