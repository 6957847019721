import { Title } from "../../title";

export const NoPage = () => {
    return (
        <Title
            title={"Oh no! No page was found!"}
            subtitle={<a href="/">Click here to return home</a>}
        />
    );
};
