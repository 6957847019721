import { Title } from "../../title";

export const Contact = () => {
    return (
        <div>
            <Title title={"Contact"} />
            Contact me with{" "}
            <a href="https://twitter.com/HouseOfTriumphs">
                @HouseOfTriumphs
            </a>{" "}
            or houseoftriumphs@gmail.com{" "}
        </div>
    );
};
