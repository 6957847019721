import { Title } from "../../title";

export const About = () => {
    return (
        <div>
            <Title title={"About me"} />
            Indie game developer from UK
        </div>
    );
};
