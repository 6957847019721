export const Title = ({ title, subtitle }) => {
    return (
        <div className="p-4">
            <div className="text-3xl py-2 font-medium border-b-2 border-gray-500">
                {title}
            </div>
            {subtitle && (
                <div className="text-md text-gray-300">{subtitle}</div>
            )}
        </div>
    );
};
