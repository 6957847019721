import "./App.css";
import { Header } from "./components/header";
import { Footer } from "./components/footer";
import { MainLayout } from "./components/main-layout";
import { Routes, Route, Navigate } from "react-router-dom";
import { Home } from "./components/pages/home/home";
import { About } from "./components/pages/about/about";
import { NoPage } from "./components/pages/nopage/nopage";
import { Cerberus } from "./components/pages/cerberus/cerberus";
import { Contact } from "./components/pages/contact/contact";

//TODOS:
// Flesh out home page
// Create the other pages (Cerberus, About, Contact)
// Fill in footer links
// reduce swearing by at least 20%
// Create a mock comments system
// Create a mock articles system
// Create some articles
// Create a backend
// Change mock systems to real systems
// Upload Cerberus screenshots

function App() {
    return (
        <div className="App">
            <MainLayout
                header={<Header />}
                body={
                    <Routes>
                        <Route path="/home" element={<Home />} />
                        <Route path="/cerberus" element={<Cerberus />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/" element={<Navigate to="/home" />} />
                        <Route path="*" element={<NoPage />} />
                    </Routes>
                }
                footer={<Footer />}
            />
        </div>
    );
}

export default App;
