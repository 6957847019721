import { Title } from "../../title";

export const Home = () => {
    return (
        <div>
            <Title
                title={"Welcome to the House of Triumphs"}
                subtitle={"The home of Cerberus"}
            />
            <div className="flex justify-center border-b border-gray-600 pt-2 pb-4">
                <div className="max-w-prose">
                    Hello! Welcome to my website. Much of this is currently
                    still under construction, but feel free to browse around. I
                    will later include screenshots and videos of my upcoming
                    game, Cerberus, as well as extra details about the person
                    behind it.
                </div>
            </div>
            <div className="flex py-8">
                <img
                    className="h-full"
                    alt="cerberus player standing on a patch of grass below some spikes"
                    src="/cerberus-screenshots/banner.png"
                />
                <div className="h-full inline-block align-middle py-32">
                    Cerberus is my dream project I have been developing for
                    almost two years. It features difficult precision
                    platforming alongside melee combat. It will be full of
                    different movement and combat abilities to aid you in your
                    journey.
                </div>
            </div>
        </div>
    );
};
