import { NavLink } from "../navlink";

export const Footer = () => {
    return (
        <div className="bg-purple-800 bottom-0 min-w-full h-48 py-4 flex justify-evenly">
            Pretend there are a bunch of bullshit Ts & Cs here
            <div>
                <NavLink
                    linkTitle={"Twitter"}
                    classNames="text-sm py-2"
                    linkEnum={"https://twitter.com/HouseOfTriumphs"}
                    isNewPath={true}
                />
            </div>
            <div>
                <div>
                    <NavLink linkTitle={"Steam"} classNames="text-sm py-2" />
                </div>
                <div>
                    <NavLink linkTitle={"Itch"} classNames="text-sm py-2" />
                </div>
                <div>
                    <NavLink linkTitle={"Humble"} classNames="text-sm py-2" />
                </div>
            </div>
        </div>
    );
};
