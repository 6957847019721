import { LINKS } from "../../shared/constants";
import { NavLink } from "../navlink";

export const Header = () => {
    return (
        <div className="bg-purple-800 sticky top-0 py-4 flex justify-between">
            <button className="text-lg pl-8 px-2 cursor-pointer hover:text-gray-50 text-gray-200">
                House of Triumphs
            </button>

            <div className="justify-between flex pr-8">
                <NavLink linkTitle="Home" linkEnum={LINKS.HOME} />
                <img
                    className="h-8 rendering-pixelated"
                    src="/cerbplayhead.png"
                    alt="Cerberus"
                />
                <NavLink linkTitle="Cerberus" linkEnum={LINKS.CERBERUS} />
                <NavLink linkTitle="About" linkEnum={LINKS.ABOUT} />
                <NavLink linkTitle="Contact" linkEnum={LINKS.CONTACT} />
            </div>
        </div>
    );
};
